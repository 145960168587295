import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Detail } from './Detail';

export function Details(props) {
    const intl = useIntl();
    const [showAllSizes, setShowAllSizes] = useState(false);


    const renderDetail = (detail, index) => {
        return (
            <Detail
                key={index}
                type={detail.type}
                name={detail.name}
                value={detail.value}
                selected={props.properties[detail.type] === detail.value}
                disabled={detail.disabled}
                setShowAllSizes={setShowAllSizes}
                showAllSizes={showAllSizes}
                goTo={props.goTo}
            />
        );
    };

    return (
      <div className={'Cashier-item-draw-details-content'}>
          {Object.keys(props.prototype).map((property) => {
              if (property === 'size') {
                  const selectedSizeDetail = props.prototype[property].find(detail => props.properties[property] === detail.value);
                  return (
                    <div key={`${props.currentKey}-size`} className={'Cashier-item-draw-details'}>
                        {showAllSizes ?
                          props.prototype[property].sort((a, b) => parseFloat(a.value) - parseFloat(b.value)).map(renderDetail)
                          :
                          renderDetail(selectedSizeDetail, 0)
                        }
                    </div>
                  );
              }
              // Render other properties as needed
              return null;
          })}
      </div>
    );
}
