import './MaxPurchaseProduct.css';
import {useIntl} from 'react-intl';
import {Context} from '../../Store';
import React, {useContext} from 'react';
import {AMPMAddAndRemoveProduct} from './AMPMAddAndRemoveProduct';
import {MaxAddAndRemoveProduct} from './MaxAddAndRemoveProduct';

export function MaxPurchaseProduct (props:{
  product:any,
  modal?:boolean,
  selectingCombo?:any,
  hideAvailableStock:boolean
}) {
  const intl = useIntl();
  const [state, dispatch] = useContext(Context);
  const cart = state.merchantParams?.noClient ? (state.cart[state.merchantParams.noClient] ? state.cart[state.merchantParams.noClient] : state.cart[0]) : state.cart[0];

  if (!cart[props.product?.id]) {
    return (
      <div style={{fontFamily:state.currentMerchant.styles.font}} className={
        ((!props.product?.quantity || props.product?.price == 0 || props.selectingCombo)?`Max-product-Purchase-unn`:`Max-product-Purchase`)}
           onClick={props.product?.quantity != 0 && props.product?.price != 0 && props.product?.id ? () => dispatch({
             type: 'ADD-CARD',
             payload: {
               [props.product.id]: 1
             }
           }) : ()=>false}>
        <div style={{ backgroundColor:`${props.product?.quantity != 0 && props.product?.price != 0?state.currentMerchant.styles.color2:"grey"}`}} className={!props.modal?(!props.product.quantity?'Max-item-withOutStock':'Max-item-withStock'): (!props.product.quantity?'Max-item-withOutStock-modal':'Max-item-withStock-modal')}>{props.product?.quantity && props.product?.price != 0 ? intl.formatMessage({id:'CASHIER_ACTIONS__BUTTON_ADD_CART'}) : !props.product ? intl.formatMessage({id:'MERCHANT_COMBO_REQUIRED'}) : intl.formatMessage({id:'CASHIER_ACTIONS__BUTTON_SOLDOUT'})}</div>
      </div>
    )
  }

  const quantity = props.product ? (props.product.quantity - (cart[props.product.id] ? cart[props.product.id] : 0)) : 0;
  let message =     quantity===0? 'Agotado':''
  if (props.product?.quantity !== -1) {
    message =  !props.hideAvailableStock
      ? quantity + (quantity > 0 ? '+' : '') + (quantity == 1 ? intl.formatMessage({id:'CASHIER_ACTIONS__LABEL_AVAILABLE'})
          : intl.formatMessage({id:'CASHIER_ACTIONS__LABEL_AVAILABLE_PLURAL'})) : '';

  }

  return <MaxAddAndRemoveProduct product={props.product} message={message}/>;
}